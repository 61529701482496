export const CountryRules: { [countryCode: string]: RegExp } = {
  // zehn, nur Ziffern; (alte neunstellige USt-IdNrn. werden durch Voranstellen der Ziffer Ø ergänzt)
  BE: /^BE\d{10}$/i,

  // neun oder zehn, nur Ziffern
  BG: /^BG\d{9,10}$/i,

  // neun, nur Ziffern
  CH: /^CHE\d{9}$/i,

  // acht, nur Ziffern
  DK: /^DK\d{8}$/i,

  // neun, nur Ziffern
  DE: /^DE\d{9}$/i,

  // neun, nur Ziffern
  EE: /^EE\d{9}$/i,

  // fünf (für Behörden) oder neun (für den Normalfall) oder zwölf (für Filialhandlungen), nur Ziffern
  GB: /^GB(\d{5}|\d{9}|\d{12})$/i,

  // acht, nur Ziffern
  FI: /^FI\d{8}$/i,

  // elf, nur Ziffern bzw. die erste und / oder die zweite Stelle kann ein Buchstabe sein
  FR: /^FR\w{2}\d{9}$/i,

  // neun, nur Ziffern
  EL: /^EL\d{9}$/i,

  //   acht, die zweite Stelle kann und die letzte Stelle muss ein Buchstabe sein
  // oder
  //   neun Stellen (ab 01.01.2013)
  //    1. - 7. Stelle Ziffern
  //    8. Stelle Buchstaben von A bis W
  //    9. Stelle Buchstaben von A bis I
  IE: /^IE\d(\w\d{5}\D$|\d{6}[A-W][A-I]$)/,

  // elf, nur Ziffern
  IT: /^IT\d{11}$/i,

  // elf, nur Ziffern
  HR: /^HR\d{11}$/i,

  // elf, nur Ziffern
  LV: /^LV\d{11}$/i,

  // neun oder zwölf, nur Ziffern
  LT: /^LT(\d{9}$)|(\d{12}$)/,

  // acht, nur Ziffern
  LU: /^LU\d{8}$/i,

  // acht, nur Ziffern
  MT: /^MT\d{8}$/i,

  // zwölf, Ziffern, Buchstaben und die Zeichen '+' und '*' kann in zufälliger Reihenfolge sein
  NL: /^NL[A-Z1-9*+]{12}$/i,

  // neun oder zwölf, nur Ziffern
  // für Verwaltungen und Gesundheitswesen:
  //      fünf, die ersten zwei Stellen GD oder HA;
  XI: /^XI(((\d{9}$)|(\d{12}$))|((GD|HA)\d{3}$))/,

  //neun, die erste Stelle muss der Buchstabe „U“ sein
  AT: /^ATU\d{8}$/i,

  // zehn, nur Ziffern
  PL: /^PL\d{10}$/i,

  // neun, nur Ziffern
  PT: /^PT\d{9}$/i,

  // maximal zehn, nur Ziffern Ziffernfolge nicht mit Ø beginnend
  RO: /^RO[1-9]\d{1,8}$/i,

  // zwölf, nur Ziffern, die beiden letzten Stellen bestehen immer aus der Ziffernkombination „Ø1“
  SE: /^SE\d{10}01$/i,

  // zehn, nur Ziffern
  SK: /^SK\d{10}$/i,

  // acht, nur Ziffern
  SI: /^SI\d{8}$/i,

  // neun, die erste und die letzte Stelle bzw. die erste oder die letzte Stelle kann ein Buchstabe sein TODO
  ES: /^ES(?=\w\d{7}\w$)(?!\d{9})/i,

  // acht, neun oder zehn, nur Ziffern
  CZ: /^CZ\d{8,10}$/i,

  // acht, nur Ziffern
  HU: /^HU\d{8}$/i,

  // neun, die letzte Stelle muss ein Buchstabe sein
  CY: /^CY\d{8}\D$/i,
};
