import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CountryRules } from './country-rules';

export class CustomValidators {
  /**
   * Validates the value is not null, not an empty string and not a blank string.
   * Returns a "required" error and therefore can be used as a drop-in replacement for Validators.required.
   */
  public static notBlank: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const isValid = !!control.value && control.value.trim().length > 0;
    return isValid ? null : { required: true };
  };

  /**
   * Validates if a value is greater than the given minimum *exclusive*.
   * (The built-in Validators.min() includes the minimum in the valid range.)
   */
  public static greaterThan(greaterThan: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || control.value === undefined || control.value.length === 0) {
        // Only validate if a value is entered.
        return null;
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value <= greaterThan
        ? { greaterThan: { greaterThan: greaterThan, actual: control.value } }
        : null;
    };
  }

  /**
   * Validates if a numeric value is an integer.
   */
  public static integer: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined || control.value.length === 0) {
      // Only validate if a value is entered.
      return null;
    }
    const value = parseFloat(control.value);
    return !isNaN(value) && !Number.isInteger(value) ? { integer: true } : null;
  };

  /**
   * Returns the given ValidatorFn only if the predicate returns true.
   */
  public static conditionalValidator(predicate: () => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        // Wait until form is available.
        return null;
      }
      return predicate() ? validator(control) : null;
    };
  }

  /**
   * Returns a validator that executes the given array of validators if the predicate evaluates to true at the moment
   * of checking.
   * @param predicate -
   * @param validators -
   */
  public static conditionalValidators(predicate: () => boolean, validators: ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        // Wait until form is available.
        return null;
      }
      if (predicate()) {
        const composed = Validators.compose(validators);
        return composed ? composed(control) : null;
      } else {
        return null;
      }
    };
  }

  public static containedIn(listOfValues: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        // Wait until form is available.
        return null;
      }
      if (!listOfValues.includes(control.value)) {
        return { valueNotContainedInList: true };
      }
      return null;
    };
  }

  /**
   * Validates if a form contains a valid VAT depending on the country code provided
   */
  public static validVat: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined || control.value.length === 0) {
      return null;
    }
    const countryCode = control.value.toString().substring(0, 2);
    let countryRule = CountryRules[countryCode.toUpperCase()];
    if (!countryRule) {
      return { unknownCountry: true };
    }
    return countryRule.test(control.value) ? null : { invalidVat: true };
  };
}
