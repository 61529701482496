/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
/// <reference types="@types/google.maps" />
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, from, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { GooglePlace } from '../../model/google-place';
import { CountriesWithoutZipcodeRules } from '../../validation/countries-without-zipcode-rules';
import { MinimalZipCodeCharacters } from '../../validation/zipcode-rules';
import { formatAddress } from './google-maps-util';
import AutocompleteService = google.maps.places.AutocompleteService;
import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
import PlacesService = google.maps.places.PlacesService;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import LatLng = google.maps.LatLng;
import AutocompleteResponse = google.maps.places.AutocompleteResponse;
import PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;

@Component({
  selector: 'app-google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss'],
})
export class GoogleMapsAutocompleteComponent implements OnInit {
  /**
   * Important: Must not be named "formControl" because that confuses the angular mat-form-field.
   */
  @Input()
  public inputControl!: AbstractControl;

  @Input()
  public checkForIncompletePostalCode: boolean = false;

  @Input()
  public checkForCountriesWithoutZipcodeRules: boolean = false;

  @Output()
  public locationSelected = new EventEmitter<GooglePlace>();

  @ViewChild(MatAutocomplete, { static: true })
  public matAutocomplete!: MatAutocomplete;

  public selectOptions$: Observable<OptionEntry[]> | undefined;
  private placesService: PlacesService | undefined;
  private autocompleteService: AutocompleteService;
  private sessionToken: AutocompleteSessionToken;

  private static readonly debounceTimeInMillis = 500;

  constructor(private zone: NgZone) {
    const gmap = document.createElement('div') as HTMLDivElement;
    this.placesService = new google.maps.places.PlacesService(gmap);
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  public ngOnInit(): void {
    this.selectOptions$ = this.createOptionsObservable(this.inputControl);

    this.initInputControlValidators();

    const initialValue: GooglePlace | undefined = this.inputControl.value;

    if (initialValue?.placeId && !initialValue.initialized) {
      // Intitial value only contains a place id, reload place info.
      this.inputControl.setValue(undefined);
      this.selectPlace(initialValue.placeId);
    }

    if (this.checkForIncompletePostalCode) {
      this.inputControl.updateValueAndValidity();
    }

    if (initialValue?.placeId && this.checkForCountriesWithoutZipcodeRules) {
      this.adjustValidationRegardingZipcodeRulesByCountry(initialValue.address.countryCode);
    }
  }

  private initInputControlValidators(): void {
    const validators = [
      GoogleMapsAutocompleteComponent.mustSelectPlaceValidator,
      GoogleMapsAutocompleteComponent.incompleteAddressValidator,
    ];
    if (this.checkForIncompletePostalCode) {
      validators.push(GoogleMapsAutocompleteComponent.incompletePostalCode);
    }

    if (this.inputControl.validator) {
      validators.unshift(this.inputControl.validator);
    }
    this.inputControl.setValidators(validators);
  }

  private createOptionsObservable(control: AbstractControl): Observable<OptionEntry[]> {
    return control.valueChanges.pipe(
      debounceTime(GoogleMapsAutocompleteComponent.debounceTimeInMillis),
      switchMap(value => this.autocompleteSuggestionsObservable(value, this.sessionToken)),
      map(response =>
        response.predictions.map(pred => {
          return {
            prediction: pred,
            htmlDisplay: this.getHighlightedOption(pred),
          };
        })
      )
    );
  }

  private autocompleteSuggestionsObservable(
    input: string | OptionEntry | GooglePlace,
    sessionToken: AutocompleteSessionToken
  ): Observable<AutocompleteResponse> {
    if (typeof input !== 'string') {
      return of({ predictions: [] });
    }
    input = input ? input : ' ';

    const request = { input: input, sessionToken: sessionToken, location: new LatLng(52, 8), radius: 500000 };
    const predictions = this.autocompleteService.getPlacePredictions(request);

    return from(predictions);
  }

  /**
   * Retrieves the details about the given place of origin and replaces the expired session token with a new one
   * @param entry selected place
   */
  public selectLocation(entry: OptionEntry): void {
    this.selectPlace(entry.prediction.place_id);
  }

  private selectPlace(placeId: string): void {
    const param: PlaceDetailsRequest = {
      placeId: placeId,
      fields: ['address_component', 'place_id'],
      sessionToken: this.sessionToken,
    };

    this.placesService?.getDetails(param, (result, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK && result) {
        const place: GooglePlace = {
          placeId: result.place_id || '',
          address: {
            street: result.address_components?.find(x => x.types.includes('route'))?.long_name,
            houseNumber: result.address_components?.find(x => x.types.includes('street_number'))?.long_name,
            zipCode: result.address_components?.find(x => x.types.includes('postal_code'))?.long_name,
            city:
              result.address_components?.find(x => x.types.includes('locality'))?.long_name ||
              result.address_components?.find(x => x.types.includes('postal_town'))?.long_name,
            country: result.address_components?.find(x => x.types.includes('country'))?.long_name,
            countryCode: result.address_components?.find(x => x.types.includes('country'))?.short_name,
          },
          initialized: true,
        };
        this.zone.run(() => {
          if (this.checkForCountriesWithoutZipcodeRules) {
            this.adjustValidationRegardingZipcodeRulesByCountry(place.address.countryCode);
          }

          this.inputControl.setValue(place);
        });

        this.locationSelected.emit(place);
      }
    });

    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  private adjustValidationRegardingZipcodeRulesByCountry(countryCode: string | undefined): void {
    if (!countryCode) {
      return;
    }

    this.inputControl.clearValidators();

    if (CountriesWithoutZipcodeRules.some(country => country === countryCode)) {
      const validators = [
        GoogleMapsAutocompleteComponent.mustSelectPlaceValidator,
        GoogleMapsAutocompleteComponent.incompleteAddressValidatorForCountryWithoutZipcodeRules,
      ];
      this.inputControl.setValidators(validators);
    } else {
      this.initInputControlValidators();
    }

    this.inputControl.updateValueAndValidity();
  }

  /**
   * Display function for AutocompletePrediction.
   */
  public getDisplayValue(val: GooglePlace | OptionEntry | string | undefined): string {
    if (!val) {
      return '';
    }
    if (GoogleMapsAutocompleteComponent.isGooglePlace(val)) {
      return formatAddress(val.address);
    }
    if (GoogleMapsAutocompleteComponent.isOptionEntry(val)) {
      return val.prediction.description;
    }

    return val;
  }

  private static isGooglePlace(val: GooglePlace | OptionEntry | string | undefined): val is GooglePlace {
    return (<GooglePlace>val)?.placeId !== undefined;
  }

  private static isOptionEntry(val: GooglePlace | OptionEntry | string | undefined): val is OptionEntry {
    return (<OptionEntry>val)?.prediction !== undefined;
  }

  public getHighlightedOption(option: google.maps.places.AutocompletePrediction): string {
    // Second expression in this condition is a workaround for the case when there is no main_text_matched_substrings in the response from Google API
    const mainTextWithHighlighting = option.structured_formatting.main_text_matched_substrings
      ? option.structured_formatting.main_text_matched_substrings.reduce((acc, cur, idx) => {
          const startIndex = cur.offset + idx * 17; // 17 equals length of '<strong></strong>'
          const before = acc.substring(0, startIndex);
          const content = acc.substring(startIndex, startIndex + cur.length);
          const after = acc.substring(startIndex + cur.length);

          return before + '<strong>' + content + '</strong>' + after;
        }, option.structured_formatting.main_text)
      : '<strong>' + option.structured_formatting.main_text + '</strong>';

    const secondaryText = option.structured_formatting.secondary_text || '';

    return `${mainTextWithHighlighting} &nbsp;<small>${secondaryText}</small>`;
  }

  private static mustSelectPlaceValidator(control: AbstractControl): ValidationErrors | null {
    if (!GoogleMapsAutocompleteComponent.isGooglePlace(control.value)) {
      return { noPlaceSelected: true };
    } else {
      return null;
    }
  }

  private static incompleteAddressValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value || !GoogleMapsAutocompleteComponent.isGooglePlace(control.value)) {
      return null;
    }
    const isMissingRequiredProperties =
      !control.value.address.zipCode || !control.value.address.country || !control.value.address.countryCode;
    return isMissingRequiredProperties ? { incompleteAddress: true } : null;
  }

  private static incompleteAddressValidatorForCountryWithoutZipcodeRules(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value || !GoogleMapsAutocompleteComponent.isGooglePlace(control.value)) {
      return null;
    }
    const isMissingRequiredProperties =
      !control.value.address.city || !control.value.address.country || !control.value.address.countryCode;
    return isMissingRequiredProperties ? { incompleteAddressForCountryWithoutZipcodeRules: true } : null;
  }

  private static incompletePostalCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value || !GoogleMapsAutocompleteComponent.isGooglePlace(control.value)) {
      return null;
    }
    const incompleteAddressValidationErrors: ValidationErrors | null =
      GoogleMapsAutocompleteComponent.incompleteAddressValidator(control);

    if (incompleteAddressValidationErrors !== null) {
      return null;
    }

    let incompletePostalCode = true;
    const minimalZipCodeCharacters = MinimalZipCodeCharacters.get(control.value.address.countryCode!)!;
    if (!minimalZipCodeCharacters) {
      return null;
    }

    let cleanedZipCode = control.value.address?.zipCode?.replace(/\s+|\-+/g, ''); // remove spaces or dashes from zip code
    if (!cleanedZipCode || cleanedZipCode.length >= minimalZipCodeCharacters) {
      incompletePostalCode = false;
    }

    return incompletePostalCode ? { incompletePostalCode: true } : null;
  }
}

interface OptionEntry {
  prediction: AutocompletePrediction;
  htmlDisplay: string;
}
